








































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Maintenance} from "@/models/maintenance";
import {MaintenanceElementCategory, MaintenanceListElement} from "@/models/maintenanceElement";

// TODO: Continue here!!!
// TODO: Continue here!!!
// TODO: Continue here!!!

@Component({
  components: {
    MaintenanceListRadio: () => import('./../components/radio/MaintenanceListRadio.component.vue'),
    MaintenanceListTableRow: () => import('./../components/MaintenanceListTableRow.component.vue'),
  }
})
export default class EditChecklistComponent extends Vue {

  data() {
    return {
      valid: false,
      required(propertyType: any) {
        return (v: any) => v && v.length > 0 || `You must input a ${propertyType}`;
      },
      maxLength(propertyType: any, maxLength: number) {
        return (v: any) => v && v.length <= maxLength || `${propertyType}  must have max  ${maxLength} characters`;
      },
      minLength(propertyType: any, min: number) {
        return (v: any) => v && v.length >= min || `${propertyType}  must have minimum  ${min} characters`;
      }
    }
  }

  @Prop({default: () => new Maintenance({})})
  public maintenance!: Maintenance;

  public conditionList: MaintenanceListElement[] = [];
  public functionList: MaintenanceListElement[] = [];

  public mounted() {
    this.conditionList = this.maintenance.maintenanceList.filter((arg) => arg.category === MaintenanceElementCategory.condition);
    this.functionList = this.maintenance.maintenanceList.filter((arg) => arg.category === MaintenanceElementCategory.function);
  }

  public closeDialog() {
    this.$emit('closeDialog', {saved: false});
  }

  public async saveChecklist() {
    // update
    this.$emit('closeDialog', {saved: true});
  }

}

